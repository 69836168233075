import { log } from '../utils/monitoring';
import { getIsSuccessfulEditorReady, setIsSuccessfulEditorReady } from './services/applicationState';

let lastAction: Promise<unknown> = Promise.resolve();
let resolveInitialPromise: (value?: unknown) => void;
let rejectInitialPromise: (value?: unknown) => void;

const initialPromise = new Promise((resolve, reject) => {
  resolveInitialPromise = resolve;
  rejectInitialPromise = reject;
});

// Actions wrapped with enforceSequentiality are executed only after startSequentialPromises is called
export const startSequentialPromises = () => {
  resolveInitialPromise();
  setIsSuccessfulEditorReady(true);
};

// Actions wrapped with enforceSequentiality are not executed after stopSequentialPromises is called
export const stopSequentialPromises = () => {
  rejectInitialPromise();
  setIsSuccessfulEditorReady(false);
};

export default async function next<T>(methodName: string, nextAction: () => Promise<unknown>) {
  try {
    await lastAction;
  } catch (e) {
    const tags = { methodName };
    const extra = { error: JSON.stringify(e), stack: JSON.stringify(e && e.stack) };
    log('Rejected promise in enforceSequentiality', { tags, extra });
    lastAction = Promise.resolve();
  }

  const isSuccessfulEditorReady = getIsSuccessfulEditorReady();
  if (isSuccessfulEditorReady) {
    lastAction = lastAction.then(nextAction);
  } else {
    lastAction = lastAction.then(() => Promise.reject(new Error('EditorReady not successful')));
  }

  return lastAction;
}

next('initial promise', () => initialPromise);
