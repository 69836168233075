import { PageSettings } from '@wix/platform-editor-sdk';

const PAGE_INFO: PageSettings = {
  title: 'Page Info',
  // @ts-ignore
  event: 'pageInfo',
  icon: 'page_dynamic_icon',
  url: './assets/pageInfo.html',
  helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
  type: 'page_info',
};

const PERMISSIONS: PageSettings = {
  title: 'Permissions',
  // @ts-ignore
  event: 'pagePermissions',
  icon: 'page_dynamic_icon',
  url: './assets/pagePermissions.html',
  helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
  type: 'permissions',
};

const PERMISSIONS_PLATFORMISED: PageSettings = {
  title: 'Permissions',
  // @ts-ignore
  event: 'pagePermissions',
  icon: 'page_dynamic_icon',
  helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
  type: 'permissions',
};

const SEO: PageSettings = {
  title: 'SEO Basics',
  // @ts-ignore
  event: 'pageSEO',
  icon: 'page_dynamic_icon',
  url: './assets/pageSEO.html',
  helpId: 'a621022f-17fa-433a-b4f5-d43994e8c4f6',
  type: 'seo',
};

export { PAGE_INFO, PERMISSIONS, PERMISSIONS_PLATFORMISED, SEO };
